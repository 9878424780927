<template>
  <v-card :color="bids" height="70" @click="showModal(detail)">
    <v-card-text>
      <v-row class="ml-0">
        <v-badge
          bordered
          bottom
          :color=" detail.is_online ? 'success' : 'pink accent-4'"
          dot
          offset-x="10"
          offset-y="10"
          class="mt-2"
        >
          <v-avatar size="50" color="grey">
            <img
							:src="avatarURl"
							:alt="detail.nickname"
							v-if="avatarURl"
							/>
							<span v-else class='text-h3'>  {{ detail.nickname ? trimFirtTwo(detail.nickname) :  detail.id  }}</span>
          </v-avatar>
        </v-badge>

        <div class="ml-5">
          <div class="text-subtitle-1 font-weight-bold  mt-2">
            {{ detail.nickname }}
          </div>
          <v-row class="mt-0  ml-0">
            <span> {{ detail.total_orders }} ORDERS {{ detail.rating }} </span>
            <v-rating
              :value="parseInt(detail.rating)"
              half-increments
              background-color="orange lighten-3"
              color="orange"
              class="mt-n2"
              readonly
              size="15"
            ></v-rating>
          </v-row>
        </div>
      </v-row>
    </v-card-text>
    <reviewModal :show="show" :detail="currentDetail" @close="show = false" />
  </v-card>
</template>
<script>
import colorMxn from "@/mixins/colorMxn";
import generalMxn from '@/mixins/general_mixin';

export default {
  name: "writerComponent",
  components: {
    reviewModal: () => import("@/modules/Writers/components/reviewModal"),
  },
  props: ["detail"],
  mixins: [colorMxn, generalMxn],
  data() {
    return {
      rating: 4.8,
      show: false,
      currentDetail: null,
    };
  },
  computed: {
     avatarURl() {
      const url = this.detail.avatar ? `${process.env.VUE_APP_BASE_URL}/avatar/download/${this.detail.avatar}` : null;
      return url;
    },
    fullName() {
      const res = this.detail.user.first_name && this.detail.user.last_name ? this.detail.user.first_name +  this.detail.user.last_name : this.detail.user.username;
      return res;
    }
  },
  methods: {
    showModal(obj) {
      this.currentDetail = obj;
      this.show = true;
    },
  }
};
</script>

<style scope></style>
